import React from 'react';

const baseUrl = 'http://localhost:5002/logos/';

const TeamStatCard = ({ teams, isHovered }) => (
  <div className={`bg-gray-800 p-4 rounded-lg shadow-lg transform transition-all duration-300 ease-in-out -translate-y-full left-0 right-0 mx-auto w-auto max-w-3xl ${isHovered ? 'block' : 'hidden'}`} style={{ top: '100%' }}>
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center">
        <img src={`${baseUrl}${teams[0]?.teamId}.png`} alt={`${teams[0]?.teamMarket} ${teams[0]?.teamName} Logo`} className="h-10 w-10 rounded-full mr-2" />
        <span className="text-lg text-white font-medium">{teams[0]?.teamMarket} {teams[0]?.teamName}</span>
      </div>
      <div className="flex items-center">
        <span className="text-lg text-white font-medium">{teams[1]?.teamMarket} {teams[1]?.teamName}</span>
        <img src={`${baseUrl}${teams[1]?.teamId}.png`} alt={`${teams[1]?.teamMarket} ${teams[1]?.teamName} Logo`} className="h-10 w-10 rounded-full ml-2" />
      </div>
    </div>
    <div className="overflow-x-auto">
      <table className="w-full text-sm border-collapse">
        <thead className="bg-gray-900">
          <tr>
            <th scope="col" className="px-4 py-3 text-white text-left font-semibold border-b border-gray-700">
              Stat
            </th>
            <th scope="col" className="px-4 py-3 text-white text-right font-semibold border-b border-gray-700">
              {teams[0]?.teamName}
            </th>
            <th scope="col" className="px-4 py-3 text-white text-right font-semibold border-b border-gray-700">
              {teams[1]?.teamName}
            </th>
          </tr>
        </thead>
        <tbody className="bg-gray-800">
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Overall Record</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.overallWins} - {teams[0]?.overallLosses}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.overallWins} - {teams[1]?.overallLosses}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Conference Record</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.confWins} - {teams[0]?.confLosses}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.confWins} - {teams[1]?.confLosses}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Points Scored Per Game</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.ptsScoredPg}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.ptsScoredPg}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Strength of Schedule</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.sos}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.sos}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Rebounds Per Game</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.rebPg}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.rebPg}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Free Throws Attempted Per Game</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.ftaPg}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.ftaPg}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Assists Per 40</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.astP40}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.astP40}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Blocks</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.blk}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.blk}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Bench Points Per Game</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.benchPtsPg}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.benchPtsPg}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Net Rating - Adjusted</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.netRtgAdj}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.netRtgAdj}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Pace - Adjusted</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.paceAdj}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.paceAdj}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Offensive Rating - Adjusted</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.ortgAdj}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.ortgAdj}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Defensive Rating - Adjusted</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.drtgAdj}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.drtgAdj}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Free Throw Percentage</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.ftPct}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.ftPct}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Three-Point Percentage</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.fg3Pct}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.fg3Pct}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Two-Point Percentage</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.fg2Pct}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.fg2Pct}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Assist Ratio</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.astRatio}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.astRatio}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Turnovers Per Game</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.tovPg}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.tovPg}</td>
          </tr>
          <tr className="hover:bg-gray-700">
            <td className="px-4 py-2 text-white border-b border-gray-700">Blocks Per Game</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[0]?.blkPg}</td>
            <td className="px-4 py-2 text-right text-white border-b border-gray-700">{teams[1]?.blkPg}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default TeamStatCard;
