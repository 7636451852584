import React, { useEffect, useState } from "react";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import "./PlayerStatsTable.css";

const PlayerStatsTable = ({ year }) => {
	const [players, setPlayers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [teamFilter, setTeamFilter] = useState("");

	useEffect(() => {
		const fetchPlayerStats = async () => {
			try {
				const response = await fetch(
					`http://localhost:5002/playersAll/${year}`,
				);
				const data = await response.json();
				console.log(data);
				setPlayers(data);
			} catch (error) {
				console.error("Error fetching player stats:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchPlayerStats();
	}, [year]);

	const filteredPlayers = React.useMemo(() => {
		if (!teamFilter) return players;
		return players.filter(player => 
			`${player.teamMarket} ${player.teamName}`.toLowerCase().includes(teamFilter.toLowerCase())
		);
	}, [players, teamFilter]);

	const columns = React.useMemo(
		() => [
			{ Header: "Name", accessor: "fullName" },
			{ Header: "Team", accessor: "classYr" },
			{ Header: "Position", accessor: "position" },
			{ Header: "Height", accessor: "height" },
			{
				Header: "Team Name",
				accessor: (row) => `${row.teamMarket} ${row.teamName}`,
			},
			{ Header: "PointsPg", accessor: "ptsScoredPg" },
			{ Header: "ReboundsPg", accessor: "rebPg" },
			{ Header: "AssistsPg", accessor: "astPg" },
			{ Header: "StealsPg", accessor: "stlPg" },
			{ Header: "BlocksPg", accessor: "blkPg" },
			{ Header: "TurnoversPg", accessor: "tovPg" },
			{ Header: "FoulsPg", accessor: "pfPg" },
			{ Header: "MinutesPg", accessor: "minsPg" },
		],
		[],
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data: filteredPlayers,
			initialState: { pageIndex: 0 },
		},
		useFilters,
		useSortBy,
		usePagination,
	);

	if (loading) return <p>Loading player stats...</p>;

	return (
		<div className="table-container">
			<h2>Player Stats for {year}</h2>
			<div className="filter-container">
				<label htmlFor="team-filter">Filter by Team: </label>
				<input
					id="team-filter"
					type="text"
					value={teamFilter}
					onChange={(e) => setTeamFilter(e.target.value)}
					placeholder="Enter team name..."
					className="team-filter-input"
				/>
				{teamFilter && (
					<button onClick={() => setTeamFilter("")} className="clear-filter">
						Clear
					</button>
				)}
			</div>
			<div className="table-wrapper">
				<table {...getTableProps()} className="player-stats-table">
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render("Header")}
										<span>
											{column.isSorted
												? column.isSortedDesc
													? " 🔽"
													: " 🔼"
												: ""}
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => (
										<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div>
				<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
					{"<<"}
				</button>{" "}
				<button onClick={() => previousPage()} disabled={!canPreviousPage}>
					{"<"}
				</button>{" "}
				<button onClick={() => nextPage()} disabled={!canNextPage}>
					{">"}
				</button>{" "}
				<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
					{">>"}
				</button>{" "}
				<span>
					Page{" "}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{" "}
				</span>
				<span>
					| Go to page:{" "}
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={(e) => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(page);
						}}
						style={{ width: "100px" }}
					/>
				</span>{" "}
				<select
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
					}}
				>
					{[10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default PlayerStatsTable;
