import React, { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import PlayerStatsTable from "./PlayerStatsTable"; // Add this import
import ScatterPlot from "./ScatterPlot"; // Adjust the import path as necessary
import TeamStatCard from "./TeamStatCard"; // Adjust the import path as necessary
import TournamentBracket from "./TournamentBracket"; // Import the new component
import ChatSidebar from "./ChatSidebar"; // Import the ChatSidebar component
import { rounds } from "./roundsData";

const useFetchTeamStats = (url) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(url);
				const json = await response.json();
				setData(json);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [url]);

	return { data, loading };
};

const baseUrl = "http://localhost:5002/logos/";

const TeamCard = ({ teams }) => {
	// Change from isHovered to isSelected
	const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);
	const theme = "dark";
	return (
		<div
			className={`team-card ${theme === "dark" ? "bg-gray-800" : "bg-white"}`}
		>
			{teams.map((team, index) => (
				<div
					key={index}
					className={`relative z-10 rounded-lg shadow-md ${theme === "dark" ? "bg-gray-700" : "bg-white hover:bg-gray-100"} cursor-pointer`}
					onClick={() => setSelectedTeamIndex(selectedTeamIndex === index ? null : index)}
				>
					<div className="p-4">
						<div className="flex items-center space-x-2">
							{/* Team logo/image */}
							<div
								className={`rounded-full h-8 w-8 ${theme === "dark" ? "bg-gray-600" : "bg-gray-200"} flex items-center justify-center overflow-hidden`}
							>
								<img
									src={`${baseUrl}${team?.teamId}.png`}
									alt={`${team?.teamMarket} ${team?.teamName} Logo`}
									className="h-8 w-8"
								/>
							</div>
							<div>
								<div
									className={`text-lg font-medium ${theme === "dark" ? "text-white" : "text-gray-900"}`}
								>
									{team?.teamMarket} {team?.teamName}
								</div>
								<div
									className={`text-sm ${theme === "dark" ? "text-gray-400" : "text-gray-600"}`}
								>
									Click for stats
								</div>
							</div>
						</div>
					</div>
					{selectedTeamIndex === index && (
						<div
							className={`absolute z-30 left-0 right-0 mx-auto ${theme === "dark" ? "bg-white" : "bg-white"}`}
						>
							<TeamStatCard
								teams={teams}
								isHovered={true}
							/>
						</div>
					)}
				</div>
			))}
		</div>
	);
};

const TeamsList = ({ teams }) => {
	const [selectedDate, setSelectedDate] = useState("3/20");

	const findTeamByName = (name) =>
		teams.find((team) => team.teamMarket === name);

	const handleDateToggle = () => {
		setSelectedDate(selectedDate === "3/20" ? "3/21" : "3/20");
	};

	return (
		<div>
			<button onClick={handleDateToggle}>
				Show games for {selectedDate === "3/20" ? "3/21" : "3/20"}
			</button>

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
				{rounds[0].seeds
					.filter((round) => round.date.startsWith(selectedDate))
					.map((round, roundIndex) => {
						const allTeamsInRound = round.teams
							.map((team) => findTeamByName(team.name))
							.filter((team) => team !== null);

						if (allTeamsInRound.length > 0) {
							return (
								<div key={roundIndex} className="card">
									{`${round?.date} ${round?.time} || ${round?.teams[0].seed} v ${round?.teams[1].seed} || SOS || ${allTeamsInRound[0]?.sos} v ${allTeamsInRound[1]?.sos} - AVG 0.55`}
									<TeamCard
										key={roundIndex}
										teams={allTeamsInRound}
										seed={round.seed}
									/>
								</div>
							);
						}
						return null;
					})}
			</div>
		</div>
	);
};

const Dropdown = ({ label, options, value, onChange }) => (
	<div>
		<label>
			{label}:
			<select value={value} onChange={onChange}>
				{options.map((option, index) => (
					<option key={index} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</label>
	</div>
);

const App = () => {
	const [teams, setTeams] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedX, setSelectedX] = useState("paceAdj");
	const [selectedY, setSelectedY] = useState("sos");
	const [scatterData, setScatterData] = useState([]);
	const [selectedYear, setSelectedYear] = useState("2025");
	const [showOnlyTourney, setShowOnlyTourney] = useState(true);
	const [xAxisFilter, setXAxisFilter] = useState('');
	const [yAxisFilter, setYAxisFilter] = useState('');
	const [isChatOpen, setIsChatOpen] = useState(false);

	// http://localhost:8000/teams/2024

	const { data: teamCardData } = useFetchTeamStats(
		`http://localhost:5002/teams/${selectedYear}`,
	); // Adjust the URL

	useEffect(() => {
		const fetchTeamStats = async () => {
			try {
				const response = await fetch(
					`http://localhost:5002/teams/${selectedYear}`,
				);
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				const data = await response.json();
				setTeams(
					data.map((team) => ({
						teamId: team.teamId,
						teamName: team.teamName,
						teamMarket: team.teamMarket,
						tourneyGamesPlayed: team.tourney_gp,
						tourneyWins: team.tourney_wins,
						tourneyLosses: team.tourney_losses,
						ast: team.ast,
						astP40: team.astP40,
						blk: team.blk,
						benchPtsPg: team.benchPtsPg,
						netRtgAdj: team.netRtgAdj,
						paceAdj: team.paceAdj,
						ortgAdj: team.ortgAdj,
						drtgAdj: team.drtgAdj,
						ftPct: team.ftPct,
						fg3Pct: team.fg3Pct,
						fg2Pct: team.fg2Pct,
						astRatio: team.astRatio,
						tovPg: team.tovPg,
						blkPg: team.blkPg,
						ptsScoredPg: team.ptsScoredPg,
						sos: team.sos,
						rebPg: team.rebPg,
						ftaPg: team.ftaPg,
					})),
				);
			} catch (error) {
				console.error("Failed to fetch team stats:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchTeamStats();
	}, [selectedYear]);

	useEffect(() => {
		// Filter the data based on both axis filters and tourney status
		const updatedScatterData = teams
			.map((team) => ({
				x: team[selectedX],
				y: team[selectedY],
				year: selectedYear,
				label: `${team.teamMarket} ${team.teamName}`,
				teamId: team.teamId,
				tourney_gp: team.tourneyGamesPlayed,
			}))
			.filter(point => {
				const meetsXFilter = !xAxisFilter || point.x >= parseFloat(xAxisFilter);
				const meetsYFilter = !yAxisFilter || point.y >= parseFloat(yAxisFilter);
				return meetsXFilter && meetsYFilter;
			});
		setScatterData(updatedScatterData);
	}, [selectedX, selectedY, teams, selectedYear, xAxisFilter, yAxisFilter]);

	const handlePrintTeams = () => {
		console.log('Visible Teams:');
		scatterData.forEach(team => {
			console.log(`${team.label} - ${selectedX}: ${team.x.toFixed(2)}, ${selectedY}: ${team.y.toFixed(2)}`);
		});
	};

	if (loading) return <p>Loading...</p>;

	const options = [
		{ label: "Assists per 40", value: "astP40" },
		{ label: "Blocks", value: "blk" },
		{ label: "Bench Points Per Game", value: "benchPtsPg" },
		{ label: "Net Rating", value: "netRtgAdj" },
		{ label: "Pace", value: "paceAdj" },
		{ label: "Offensive Rating", value: "ortgAdj" },
		{ label: "Defensive Rating", value: "drtgAdj" },
		{ label: "Free Throw Percentage", value: "ftPct" },
		{ label: "Three-Point Percentage", value: "fg3Pct" },
		{ label: "Two-Point Percentage", value: "fg2Pct" },
		{ label: "Assist Ratio", value: "astRatio" },
		{ label: "Turnovers Per Game", value: "tovPg" },
		{ label: "Blocks Per Game", value: "blkPg" },
		{ label: "Points Scored Per Game", value: "ptsScoredPg" },
		{ label: "Strength of Schedule", value: "sos" },
		{ label: "Rebounds Per Game", value: "rebPg" },
		{ label: "FTs Attempted Per Game", value: "ftaPg" },
	];

	const optionsYears = [
		{ label: "2019", value: "2019" },
		// { label: '2020', value: '2020' },
		{ label: "2021", value: "2021" },
		{ label: "2022", value: "2022" },
		{ label: "2023", value: "2023" },
		{ label: "2024", value: "2024" },
		{ label: "2025", value: "2025" },
	];

	// Collect all the data that should be available to the chat
	const chatAppData = {
		teams,
		scatterData,
		selectedX,
		selectedY,
		selectedYear,
		showOnlyTourney,
	};

	return (
		<div className="App">
			<nav className="app-navigation">
				<ul>
					<li>
						<Link to="/">Home</Link>
					</li>
					<li>
						<Link to="/bracket">Tournament Bracket</Link>
					</li>
				</ul>
			</nav>

			<Routes>
				<Route path="/bracket" element={<TournamentBracket />} />
				<Route path="/" element={
					<div className="container">
						<h1>College Basketball Stats</h1>
						<div className="filter-controls">
							<Dropdown
								label="X-Axis Stat"
								options={options}
								value={selectedX}
								onChange={(e) => setSelectedX(e.target.value)}
							/>
							<Dropdown
								label="Y-Axis Stat"
								options={options}
								value={selectedY}
								onChange={(e) => setSelectedY(e.target.value)}
							/>
							<Dropdown
								label="Year"
								options={optionsYears}
								value={selectedYear}
								onChange={(e) => setSelectedYear(e.target.value)}
							/>
							<label>
								<input
									type="checkbox"
									checked={showOnlyTourney}
									onChange={() => setShowOnlyTourney(!showOnlyTourney)}
								/>
								Show Only Tournament Teams
							</label>
						</div>
						<div className="filter-controls">
							<label>
								Min {selectedX} value:
								<input
									type="number"
									step="0.1"
									value={xAxisFilter}
									onChange={(e) => setXAxisFilter(e.target.value)}
									placeholder="Enter minimum value"
								/>
							</label>
							<label>
								Min {selectedY} value:
								<input
									type="number"
									step="0.1"
									value={yAxisFilter}
									onChange={(e) => setYAxisFilter(e.target.value)}
									placeholder="Enter minimum value"
								/>
							</label>
						</div>
						<button 
							className="print-teams-button" 
							onClick={handlePrintTeams}
						>
							Print Visible Teams
						</button>
						<ScatterPlot
							className="pb-8"
							data={scatterData}
							selectedX={selectedX}
							selectedY={selectedY}
							selectedYear={selectedYear}
							showOnlyTourney={showOnlyTourney}
						/>

						<div>
							{selectedYear === "2025" && (
								<div>
									<h1 className="pt-8">Tournament Teams</h1>
									<TeamsList teams={teamCardData} />
								</div>
							)}
						</div>

						<PlayerStatsTable year={selectedYear} />

						{/* Chat toggle button */}
						<button 
							className="chat-toggle-button" 
							onClick={() => setIsChatOpen(true)}
							type="button"
							aria-label="Open chat assistant"
						>
							💬
						</button>

						{/* Chat sidebar */}
						<ChatSidebar 
							isOpen={isChatOpen} 
							onClose={() => setIsChatOpen(false)} 
							appData={chatAppData}
						/>
					</div>
				} />
			</Routes>
		</div>
	);
};

export default App;
