import React, { useState, useEffect } from 'react';
import { rounds } from './roundsData';
import './TournamentBracket.css';

// Add this new context to share player data across components
const PlayerDataContext = React.createContext([]);

const TeamCard = ({ team, showDetails }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [topPlayers, setTopPlayers] = useState([]);
  const allPlayers = React.useContext(PlayerDataContext);
  
  useEffect(() => {
    // Filter players for the current team when hovering
    if (isHovered && allPlayers.length > 0) {
      // Filter players for the current team
      const teamPlayers = allPlayers.filter(player => 
        `${player.teamMarket}`.toLowerCase() === team.name.toLowerCase()
      );
      
      // Sort by minutes per game and take top 7
      const sortedPlayers = teamPlayers
        .sort((a, b) => b.minsPg - a.minsPg)
        .slice(0, 7);
        
      setTopPlayers(sortedPlayers);
    }
  }, [isHovered, team.name, allPlayers]);
  
  return (
    <div 
      className={`bracket-team ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ position: 'relative' }}
    >
      <div className="team-seed-name">
        <span className="seed">{team.seed}</span>
        <span className="team-name">{team.name}</span>
        {showDetails && (
          <div className="team-record">
            <span>Off Rtg: {team.ortgAdj} Def Rtg: {team.drtgAdj}</span>
          </div>
        )}
      </div>
      
      {isHovered && (
        <div className="team-players-popup" style={{
          position: 'absolute',
          top: '100%',
          left: '0',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          padding: '8px',
          borderRadius: '4px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
          zIndex: 1000,
          width: '420px',
          maxWidth: '450px',
          overflow: 'visible',
          pointerEvents: 'auto'
        }}>
          <h4 style={{ margin: '0 0 5px 0' }}>Top Players</h4>
          {allPlayers.length === 0 ? (
            <p>Loading players...</p>
          ) : topPlayers.length > 0 ? (
            <table className="player-stats-table" style={{
              width: '100%',
              borderCollapse: 'collapse',
              fontSize: '0.8rem',
              tableLayout: 'fixed'
            }}>
              <thead>
                <tr style={{ backgroundColor: '#f2f2f2' }}>
                  <th style={{ padding: '2px 2px', textAlign: 'left', width: '100px' }}>Name</th>
                  <th style={{ padding: '2px 1px', textAlign: 'center', width: '25px' }}>Pos</th>
                  <th style={{ padding: '2px 1px', textAlign: 'center', width: '25px' }}>Yr</th>
                  <th style={{ padding: '2px 1px', textAlign: 'center', width: '25px' }}>Ht</th>
                  <th style={{ padding: '2px 1px', textAlign: 'center', width: '25px' }}>Pts</th>
                  <th style={{ padding: '2px 1px', textAlign: 'center', width: '25px' }}>Reb</th>
                  <th style={{ padding: '2px 1px', textAlign: 'center', width: '25px' }}>Ast</th>
                  <th style={{ padding: '2px 1px', textAlign: 'center', width: '25px' }}>Stl</th>
                  <th style={{ padding: '2px 1px', textAlign: 'center', width: '25px' }}>Blk</th>
                  <th style={{ padding: '2px 1px', textAlign: 'center', width: '25px' }}>Tov</th>
                  <th style={{ padding: '2px 1px', textAlign: 'center', width: '25px' }}>Min</th>
                </tr>
              </thead>
              <tbody>
                {topPlayers.map((player, index) => (
                  <tr key={index} style={{ 
                    backgroundColor: index % 2 === 0 ? 'white' : '#f9f9f9',
                    borderBottom: '1px solid #eee'
                  }}>
                    <td style={{ 
                      padding: '2px 2px', 
                      textAlign: 'left', 
                      maxWidth: '100px',
                      whiteSpace: 'nowrap', 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis' 
                    }}>{player.fullName}</td>
                    <td style={{ padding: '2px 1px', textAlign: 'center' }}>{player.position}</td>
                    <td style={{ padding: '2px 1px', textAlign: 'center' }}>{player.classYr}</td>
                    <td style={{ padding: '2px 1px', textAlign: 'center' }}>{player.height}</td>
                    <td style={{ padding: '2px 1px', textAlign: 'center' }}>{player.ptsScoredPg?.toFixed(1)}</td>
                    <td style={{ padding: '2px 1px', textAlign: 'center' }}>{player.rebPg?.toFixed(1)}</td>
                    <td style={{ padding: '2px 1px', textAlign: 'center' }}>{player.astPg?.toFixed(1)}</td>
                    <td style={{ padding: '2px 1px', textAlign: 'center' }}>{player.stlPg?.toFixed(1)}</td>
                    <td style={{ padding: '2px 1px', textAlign: 'center' }}>{player.blkPg?.toFixed(1)}</td>
                    <td style={{ padding: '2px 1px', textAlign: 'center' }}>{player.tovPg?.toFixed(1)}</td>
                    <td style={{ padding: '2px 1px', textAlign: 'center' }}>{player.minsPg?.toFixed(1)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No player data available</p>
          )}
        </div>
      )}
    </div>
  );
};

const Matchup = ({ matchup, roundIndex }) => {
  return (
    <div className={`matchup round-${roundIndex}`} style={{ overflow: 'visible', position: 'relative' }}>
      <div className="matchup-teams" style={{ overflow: 'visible' }}>
        {matchup.teams.map((team) => (
          <TeamCard 
            key={`${team.seed}-${team.name}`} 
            team={team} 
            showDetails={true}
          />
        ))}
      </div>
      <div className="matchup-details">
        <span>{matchup.date} • {matchup.time}</span>
      </div>
    </div>
  );
};

const Round = ({ round, roundIndex }) => {
  return (
    <div className={`round round-${roundIndex}`}>
      <h3 className="round-title">{round.title}</h3>
      <div className="round-matchups">
        {round.seeds.map((matchup) => (
          <Matchup 
            key={matchup.id} 
            matchup={matchup} 
            roundIndex={roundIndex}
          />
        ))}
      </div>
    </div>
  );
};

const TournamentBracket = () => {
  const [allPlayers, setAllPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch all player data once when component mounts
    const fetchPlayerData = async () => {
      try {
        const response = await fetch(`http://localhost:5002/playersAll/2025`);
        const data = await response.json();
        setAllPlayers(data);
      } catch (error) {
        console.error("Error fetching player data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerData();
  }, []);

  if (loading) {
    return <div>Loading tournament data...</div>;
  }

  return (
    <PlayerDataContext.Provider value={allPlayers}>
      <div className="tournament-bracket-container">
        <h1>NCAA Tournament Bracket</h1>
        <div className="tournament-bracket">
          {rounds.map((round, index) => (
            <Round 
              key={`round-${index}`} 
              round={round} 
              roundIndex={index}
            />
          ))}
        </div>
      </div>
    </PlayerDataContext.Provider>
  );
};

export default TournamentBracket; 