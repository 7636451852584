export const rounds: RoundProps[] = [
  {
    title: 'Round One',
    seeds: [
    {
      "id": 33,
      "teams": [
        { "seed": 9, "name": "Creighton" },
        { "seed": 8, "name": "Louisville" }
      ],
      "date": "3/20",
      "time": "12:15 PM"
    },
    {
      "id": 34,
      "teams": [
        { "seed": 13, "name": "High Point" },
        { "seed": 4, "name": "Purdue" }
      ],
      "date": "3/20",
      "time": "12:40 PM"
    },
    {
      "id": 35,
      "teams": [
        { "seed": 14, "name": "Montana" },
        { "seed": 3, "name": "Wisconsin" }
      ],
      "date": "3/20",
      "time": "1:30 PM"
    },
    {
      "id": 36,
      "teams": [
        { "seed": 16, "name": "SIUE" },
        { "seed": 1, "name": "Houston" }
      ],
      "date": "3/20",
      "time": "2:00 PM"
    },
    {
      "id": 37,
      "teams": [
        { "seed": 16, "name": "Saint Francis/Alabama St." },
        { "seed": 1, "name": "Auburn" }
      ],
      "date": "3/20",
      "time": "2:50 PM"
    },
    {
      "id": 38,
      "teams": [
        { "seed": 12, "name": "McNeese" },
        { "seed": 5, "name": "Clemson" }
      ],
      "date": "3/20",
      "time": "3:15 PM"
    },
    {
      "id": 39,
      "teams": [
        { "seed": 11, "name": "VCU" },
        { "seed": 6, "name": "BYU" }
      ],
      "date": "3/20",
      "time": "4:05 PM"
    },
    {
      "id": 40,
      "teams": [
        { "seed": 9, "name": "Georgia" },
        { "seed": 8, "name": "Gonzaga" }
      ],
      "date": "3/20",
      "time": "4:35 PM"
    },
    {
      "id": 41,
      "teams": [
        { "seed": 15, "name": "Wofford" },
        { "seed": 2, "name": "Tennessee" }
      ],
      "date": "3/20",
      "time": "6:50 PM"
    },
    {
      "id": 42,
      "teams": [
        { "seed": 10, "name": "Arkansas" },
        { "seed": 7, "name": "Kansas" }
      ],
      "date": "3/20",
      "time": "7:10 PM"
    },
    {
      "id": 43,
      "teams": [
        { "seed": 13, "name": "Yale" },
        { "seed": 4, "name": "Texas A&M" }
      ],
      "date": "3/20",
      "time": "7:25 PM"
    },
    {
      "id": 44,
      "teams": [
        { "seed": 11, "name": "Drake" },
        { "seed": 6, "name": "Missouri" }
      ],
      "date": "3/20",
      "time": "7:35 PM"
    },
    {
      "id": 45,
      "teams": [
        { "seed": 10, "name": "Utah St." },
        { "seed": 7, "name": "UCLA" }
      ],
      "date": "3/20",
      "time": "9:25 PM"
    },
    {
      "id": 46,
      "teams": [
        { "seed": 15, "name": "Omaha" },
        { "seed": 2, "name": "St. John's (NY)" }
      ],
      "date": "3/20",
      "time": "9:45 PM"
    },
    {
      "id": 47,
      "teams": [
        { "seed": 12, "name": "UC San Diego" },
        { "seed": 5, "name": "Michigan" }
      ],
      "date": "3/20",
      "time": "10:00 PM"
    },
    {
      "id": 48,
      "teams": [
        { "seed": 14, "name": "UNCW" },
        { "seed": 3, "name": "Texas Tech" }
      ],
      "date": "3/20",
      "time": "10:10 PM"
    },
    
    // Friday, March 21
    {
      "id": 49,
      "teams": [
        { "seed": 9, "name": "Baylor" },
        { "seed": 8, "name": "Mississippi St." }
      ],
      "date": "3/21",
      "time": "12:15 PM"
    },
    {
      "id": 50,
      "teams": [
        { "seed": 15, "name": "Robert Morris" },
        { "seed": 2, "name": "Alabama" }
      ],
      "date": "3/21",
      "time": "12:40 PM"
    },
    {
      "id": 51,
      "teams": [
        { "seed": 14, "name": "Lipscomb" },
        { "seed": 3, "name": "Iowa St." }
      ],
      "date": "3/21",
      "time": "1:30 PM"
    },
    {
      "id": 52,
      "teams": [
        { "seed": 12, "name": "Colorado St." },
        { "seed": 5, "name": "Memphis" }
      ],
      "date": "3/21",
      "time": "2:00 PM"
    },
    {
      "id": 53,
      "teams": [
        { "seed": 16, "name": "Mount St. Mary's/American" },
        { "seed": 1, "name": "Duke" }
      ],
      "date": "3/21",
      "time": "2:50 PM"
    },
    {
      "id": 54,
      "teams": [
        { "seed": 10, "name": "Vanderbilt" },
        { "seed": 7, "name": "Saint Mary's (CA)" }
      ],
      "date": "3/21",
      "time": "3:15 PM"
    },
    {
      "id": 55,
      "teams": [
        { "seed": 11, "name": "North Carolina/SDSU" },
        { "seed": 6, "name": "Ole Miss" }
      ],
      "date": "3/21",
      "time": "4:05 PM"
    },
    {
      "id": 56,
      "teams": [
        { "seed": 13, "name": "Grand Canyon" },
        { "seed": 4, "name": "Maryland" }
      ],
      "date": "3/21",
      "time": "4:35 PM"
    },
    {
      "id": 57,
      "teams": [
        { "seed": 16, "name": "Norfolk St." },
        { "seed": 1, "name": "Florida" }
      ],
      "date": "3/21",
      "time": "6:50 PM"
    },
    {
      "id": 58,
      "teams": [
        { "seed": 14, "name": "Troy" },
        { "seed": 3, "name": "Kentucky" }
      ],
      "date": "3/21",
      "time": "7:10 PM"
    },
    {
      "id": 59,
      "teams": [
        { "seed": 10, "name": "New Mexico" },
        { "seed": 7, "name": "Marquette" }
      ],
      "date": "3/21",
      "time": "7:25 PM"
    },
    {
      "id": 60,
      "teams": [
        { "seed": 13, "name": "Akron" },
        { "seed": 4, "name": "Arizona" }
      ],
      "date": "3/21",
      "time": "7:35 PM"
    },
    {
      "id": 61,
      "teams": [
        { "seed": 9, "name": "Oklahoma" },
        { "seed": 8, "name": "UConn" }
      ],
      "date": "3/21",
      "time": "9:25 PM"
    },
    {
      "id": 62,
      "teams": [
        { "seed": 11, "name": "Xavier/Texas" },
        { "seed": 6, "name": "Illinois" }
      ],
      "date": "3/21",
      "time": "9:45 PM"
    },
    {
      "id": 63,
      "teams": [
        { "seed": 15, "name": "Bryant" },
        { "seed": 2, "name": "Michigan St." }
      ],
      "date": "3/21",
      "time": "10:00 PM"
    },
    {
      "id": 64,
      "teams": [
        { "seed": 12, "name": "Liberty" },
        { "seed": 5, "name": "Oregon" }
      ],
      "date": "3/21",
      "time": "10:10 PM"
    }
    ]
  }
];
