import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { useChat } from 'ai/react';
import ReactMarkdown from 'react-markdown';
import './ChatSidebar.css';

const ChatSidebar = ({ isOpen, onClose, appData }) => {
  // Add local state to track messages if useChat isn't working
  const [localMessages, setLocalMessages] = useState([
    {
      id: 'welcome',
      role: 'assistant',
      content: 'Hi! I can help you analyze the basketball data on screen. What would you like to know?'
    }
  ]);
  
  // Add error state to display to user
  const [error, setError] = useState(null);
  
  // Process appData to reduce size by limiting rows
  const processedAppData = useMemo(() => {
    if (!appData) return null;
    
    // Log original size for debugging
    const originalSize = JSON.stringify(appData).length;
    console.log(`Original appData size: ~${Math.round(originalSize / 1024)} KB`);
    
    // Create a trimmed version of appData
    const trimmed = { ...appData };
    
    // Determine if appData is an array or has array properties
    if (Array.isArray(trimmed)) {
      // If appData itself is an array, limit to first 15 items
      const limitedData = trimmed.slice(0, 15);
      console.log(`Reduced array from ${trimmed.length} to 15 items`);
      
      // Log new size
      const newSize = JSON.stringify(limitedData).length;
      console.log(`Reduced appData size: ~${Math.round(newSize / 1024)} KB`);
      
      return limitedData;
    } 
    else if (typeof trimmed === 'object') {
      // For each property that is an array, limit it
      Object.keys(trimmed).forEach(key => {
        if (Array.isArray(trimmed[key]) && trimmed[key].length > 15) {
          console.log(`Reducing ${key} from ${trimmed[key].length} to 15 items`);
          trimmed[key] = trimmed[key].slice(0, 15);
          // Add metadata about trimming
          trimmed[`${key}_info`] = `Showing 15 of ${appData[key].length} total items`;
        }
      });
      
      // Log new size
      const newSize = JSON.stringify(trimmed).length;
      console.log(`Reduced appData size: ~${Math.round(newSize / 1024)} KB`);
      
      return trimmed;
    }
    
    return trimmed;
  }, [appData]);
  
  const { messages: aiMessages, input, handleInputChange, handleSubmit: originalHandleSubmit, isLoading } = useChat({
    api: '/api/chat',
    initialMessages: [
      {
        id: 'welcome',
        role: 'assistant',
        content: 'Hi! I can help you analyze the basketball data on screen. What would you like to know?'
      }
    ],
    body: {
      appData: processedAppData
    },
    onResponse: (response) => {
      console.log('Chat API response received:', response);
      
      // Clear any previous errors
      setError(null);
      
      // Check for error status
      if (!response.ok) {
        console.error(`API error: ${response.status} ${response.statusText}`);
        if (response.status === 413) {
          setError("Error: The data payload is still too large. Please try a more specific question.");
          return response;
        }
      }
      
      const clonedResponse = response.clone();
      clonedResponse.text().then(text => {
        console.log('Response content:', text);
        try {
          const responseData = JSON.parse(text);
          // Add the response to our local messages
          setLocalMessages(prev => [
            ...prev, 
            responseData
          ]);
        } catch (e) {
          console.error('Failed to parse response JSON:', e);
        }
      }).catch(err => {
        console.error('Error reading response text:', err);
      });
      return response;
    },
    onFinish: (message) => {
      console.log('Message finished processing:', message);
    },
    onError: (error) => {
      console.error('Chat API error:', error);
      setError(`Error: ${error.message || 'Failed to send message. The data might be too large.'}`);
    }
  });
  
  // Custom submit handler to add user message to local state
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    
    // Reset error state on new submission
    setError(null);
    
    // Add user message to local messages
    setLocalMessages(prev => [
      ...prev,
      {
        id: Date.now().toString(),
        role: 'user',
        content: input
      }
    ]);
    
    // Call original submit handler
    originalHandleSubmit(e);
  };
  
  // Log messages whenever they change
  useEffect(() => {
    console.log('AI messages:', aiMessages);
    console.log('Local messages:', localMessages);
  }, [aiMessages, localMessages]);
  
  // Use localMessages instead of messages from useChat
  const messagesToDisplay = localMessages;
  
  const messagesEndRef = useRef(null);
  
  // Scroll to bottom function
  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);
  
  // Initial scroll
  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  // Update scroll position when messages change
  useEffect(() => {
    if (messagesToDisplay.length > 0) {
      scrollToBottom();
    }
  }, [messagesToDisplay.length, scrollToBottom]);

  return (
    <div className={`chat-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="chat-header">
        <h3>Basketball Data Assistant</h3>
        <button className="close-button" onClick={onClose} type="button">×</button>
      </div>
      
      <div className="chat-messages">
        {localMessages.map((message) => (
          <div 
            key={message.id} 
            className={`message ${message.role === 'assistant' ? 'assistant' : 'user'}`}
          >
            <div className="message-content">
              <ReactMarkdown>
                {message.content}
              </ReactMarkdown>
            </div>
          </div>
        ))}
        
        {/* Display error message if there is one */}
        {error && (
          <div className="message error">
            <div className="message-content">
              <p>{error}</p>
            </div>
          </div>
        )}
        
        <div ref={messagesEndRef} />
      </div>
      
      <form className="chat-input-container" onSubmit={handleSubmit}>
        <input
          className="chat-input"
          value={input}
          onChange={handleInputChange}
          placeholder="Ask about the basketball data..."
          disabled={isLoading}
        />
        <button 
          type="submit" 
          className="send-button"
          disabled={isLoading || !input.trim()}
        >
          {isLoading ? '...' : 'Send'}
        </button>
      </form>
    </div>
  );
};

export default ChatSidebar; 